<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden"
        ref="container"
        style="height:100%"
    >
        <div
            v-if="type == 'followUp'"
            style="position: absolute; top: 15px; right: 15px"
        >
            <v-btn rounded color="primary" @click="downloadReport">
                REPORT
            </v-btn>
        </div>
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table
                    id="virtual-scroll-table"
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    @click:row="openQuote"
                    :style="`height:${this.quotesHeight}px`"
                    :single-expand="false"
                    :expanded.sync="expanded"
                    show-expand
                    :item-class="applyBoldClass"
                >
                    <template v-slot:expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            class="py-4 pr-4 pl-12  ma-0 outlined"
                            style="background: white"
                        >
                            <Costs :quoteId="item.quoteId" :quote="item" />
                        </td>
                    </template>
                    <template v-slot:[`header.id`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteNumber"
                            prepend-icon="mdi-filter"
                            style="width: 100px"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('id')"
                                >
                                    <v-icon v-if="show"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>

                    <template v-slot:[`header.client`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteClient"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.project`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteProject"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.country`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteCountry"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.currency`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteCurrency"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.price`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quotePrice"
                            prepend-icon="mdi-filter"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('price')"
                                >
                                    <v-icon v-if="show"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>
                    <template v-slot:[`header.exchange`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteTrm"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteAccountManager"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.collaborators`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteCollaborator"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.tags`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteTag"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.probability`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteProbability"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <template v-slot:[`header.sentDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteSentDate"
                            prepend-icon="mdi-filter"
                            style="width: 100%"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('sentDate')"
                                >
                                    <v-icon v-if="show"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>

                    <template v-slot:[`header.days`]="{ header }">
                        <v-text-field
                            v-if="type == 'followUp'"
                            :label="header.text"
                            v-model="days"
                            prepend-icon="mdi-filter"
                            style="width: 100%"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    class="mt-n2 text-uppercase"
                                    @click="activateSort('days')"
                                >
                                    <v-icon v-if="showDays"
                                        >mdi-arrow-up-bold</v-icon
                                    >
                                    <v-icon v-else>mdi-arrow-down-bold</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>

                    <template v-slot:[`header.lastMessage`]="{ header }">
                        <p class="my-0" style="font-size: 16px">
                            {{ header.text }}
                        </p>
                    </template>

                    <template v-slot:[`header.expectedDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="quoteExpectedDate"
                            prepend-icon="mdi-filter"
                        />
                    </template>

                    <!--ITEMS-->
                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div class="my-4">
                            <p
                                v-for="(collaborator,
                                index) of item.collaborators"
                                :key="index"
                                class="my-0 mx-0"
                            >
                                {{ collaborator }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.price`]="{ item }">
                        <div class="my-4">
                            <p class="my-0 mx-0">
                                {{ formatCurrency(item) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.tags`]="{ item }">
                        <div class="my-4">
                            <v-chip
                                v-for="(tag, index) of item.tags"
                                :key="index"
                                class="my-1 mx-1"
                            >
                                {{ tag }}
                            </v-chip>
                        </div>
                    </template>

                    <template v-slot:[`item.probability`]="{ item }">
                        <div class="my-4">
                            {{
                                item.probability
                                    ? item.probability.toUpperCase()
                                    : ''
                            }}
                        </div>
                    </template>

                    <template v-slot:[`item.sentDate`]="{ item }">
                        <div class="my-4">
                            {{ getDateFormat(item.sentDate) }}
                        </div>
                    </template>

                    <template v-slot:[`item.days`]="{ item }">
                        <div v-if="type == 'followUp'" class="my-4">
                            {{ getDays(item) }}
                        </div>
                    </template>

                    <template v-slot:[`item.lastMessage`]="{ item }">
                        <div
                            v-if="type == 'followUp' && chats.length > 0"
                            class="my-4"
                        >
                            {{ daysSinceLastMessage(item) }}
                        </div>
                    </template>

                    <template v-slot:[`item.expectedDate`]="{ item }">
                        <div class="my-4">
                            {{ item.expectedDate }}
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!--QUOTE-->
        <v-dialog :retain-focus="false" v-model="openQuoteDialog" fullscreen>
            <v-card v-if="openQuoteDialog" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="openQuoteDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <Quote
                        :id="selectedQuote.id"
                        :quote="selectedQuote"
                        :settings="settings"
                        :history="!!selectedQuote.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <Errors />
    </v-container>
</template>

<script>
import API from '@/services/api'
import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'QuotesByStatus',
    props: {
        type: {
            type: String,
            default: () => '',
        },
        quotes: {
            type: Array,
            default() {
                return []
            },
        },
        settings: {
            type: Array,
            default() {
                return []
            },
        },
    },
    components: {
        Costs: () => import('@/components/Quotes/Costs'),
        Errors: () => import('@/components/Layout/Errors'),
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data() {
        return {
            selectedQuote: {},
            heightPopUp: 0,
            openQuoteDialog: false,
            days: null,
            expanded: [],
            height: 0,
            quoteNumber: '',
            quoteClient: '',
            quoteProject: '',
            quoteCountry: '',
            quotePrice: '',
            quoteTrm: '',
            quoteCurrency: '',
            quoteAccountManager: '',
            quoteCollaborator: '',
            quoteTag: '',
            quoteProbability: '',
            quoteExpectedDate: '',
            quoteSentDate: '',
            headers: [
                { text: '', value: 'data-table-expand' },
                {
                    text: 'ID',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                    width: 100,
                },
                {
                    text: 'Client',
                    value: 'client',
                    sortable: false,
                    align: 'left',
                    width: 200,
                },
                {
                    text: 'Project',
                    value: 'project',
                    sortable: false,
                    align: 'left',
                    width: 200,
                },
                {
                    text: 'Country',
                    value: 'country',
                    sortable: false,
                    align: 'left',
                    width: 200,
                },
                {
                    text: 'Account Manager',
                    value: 'accountManager',
                    sortable: false,
                    align: 'center',
                    width: 230,
                },
                {
                    text: 'Currency',
                    value: 'currency',
                    sortable: false,
                    align: 'center',
                    width: 150,
                },
                {
                    text: 'Price',
                    value: 'price',
                    sortable: false,
                    align: 'right',
                    width: 200,
                },
                // {
                //     text: 'TRM',
                //     value: 'exchange',
                //     sortable: false,
                //     align: 'right',
                //     width: 200,
                // },
                {
                    text: 'Sent Date',
                    value: 'sentDate',
                    sortable: false,
                    align: 'center',
                    width: 200,
                },
                {
                    text: 'Probability',
                    value: 'probability',
                    sortable: false,
                    align: 'center',
                    width: 200,
                },
                {
                    text: 'Expected Date',
                    value: 'expectedDate',
                    sortable: false,
                    align: 'center',
                    width: 200,
                },
                {
                    text: 'Tags',
                    value: 'tags',
                    sortable: false,
                    align: 'center',
                    width: 200,
                },
                {
                    text: 'Collaborators',
                    value: 'collaborators',
                    sortable: false,
                    align: 'center',
                    width: 200,
                },
            ],
            loading: false,
            errorMessage: '',
            costs: undefined,
            quotesHeight: 0,
            show: true,
            localQuotes: [],
            parameter: 'id',
            showDays: true,
            chats: [],
        }
    },
    inject: ['openQuoteFromTable'],
    async mounted() {
        this.localQuotes = _.cloneDeep(this.quotes)
        if (this.type == 'followUp') {
            // retrieve chats
            this.chats = await API.getChatsInFollowUp()
            // set headers
            this.headers.splice(8, 0, {
                text: 'Days',
                value: 'days',
                sortable: false,
                align: 'center',
                width: 200,
            })
            this.headers.splice(9, 0, {
                text: 'Last Message',
                value: 'lastMessage',
                sortable: false,
                align: 'center',
                width: 200,
            })
        }
    },
    computed: {
        ...mapState(['costQuotes']),
        filters() {
            let conditions = []

            this.sortItems()

            if (this.quoteNumber) {
                conditions.push(this.filterNumber)
            }

            if (this.quoteClient) {
                conditions.push(this.filterClient)
            }

            if (this.quoteProject) {
                conditions.push(this.filterProject)
            }

            if (this.quoteCountry) {
                conditions.push(this.filterCountry)
            }

            if (this.quoteCurrency) {
                conditions.push(this.filterCurrency)
            }

            if (this.quotePrice) {
                conditions.push(this.filterPrice)
            }
            if (this.quoteTrm) {
                conditions.push(this.filterTrm)
            }

            if (this.quoteAccountManager) {
                conditions.push(this.filterAccountManager)
            }

            if (this.quoteCollaborator) {
                conditions.push(this.filterCollaborators)
            }

            if (this.quoteTag) {
                conditions.push(this.filterTags)
            }

            if (this.quoteProbability) {
                conditions.push(this.filterProbability)
            }

            if (this.quoteSentDate) {
                conditions.push(this.filterSentDate)
            }

            if (this.days) {
                conditions.push(this.filterDays)
            }

            if (this.quoteExpectedDate) {
                conditions.push(this.filterExpectedDate)
            }

            if (conditions.length > 0) {
                return this.localQuotes.filter(quote => {
                    return conditions.every(condition => {
                        return condition(quote)
                    })
                })
            }
            return this.localQuotes
        },
    },
    methods: {
        ...mapActions(['addCostAction']),
        ...mapMutations(['setErrorItems']),
        applyBoldClass(item) {
            const selectedChat = this.chats.find(
                chat => chat.id == item.quoteId
            )
            if (selectedChat && !selectedChat.followUpDate) {
                return 'background-color: alertRow !important; font-weight: bold;'
            }
            if (
                selectedChat &&
                selectedChat.followUpDate <
                    new Date().toISOString().slice(0, 10)
            ) {
                return 'background-color: alertRow !important; font-weight: bold;'
            }
            return 'bold-text'
        },
        downloadReport() {
            try {
                this.loading = true
                const groupByClient = this.groupByClient(this.quotes)
                const reportData = []
                for (const client of Object.values(groupByClient)) {
                    client.total = Object.keys(client).reduce(
                        (counter, key) =>
                            key != 'client' && key != 'currency'
                                ? client[key] + counter
                                : counter,
                        0
                    )
                    Object.keys(client).forEach(key => {
                        if (key != 'client' && key != 'currency') {
                            client[key] = parseFloat(client[key].toFixed(2))
                        }
                    })
                    reportData.push(client)
                }
                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(reportData)

                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'report') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `quotesFollowUp.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        groupByClient(quotes) {
            const groupByClient = {}
            if (quotes.length > 0) {
                const exchange = this.settings.find(s => s.name === 'Exchange')
                if (!exchange) {
                    return null
                }
                for (const quote of quotes) {
                    const rate = exchange.exchanges.find(
                        e => e.currency === 'USD'
                    )
                    const conversion =
                        'USD' == quote.currency
                            ? quote.price
                            : quote.price / parseFloat(rate.money)
                    const days = this.getDays(quote)
                    if (groupByClient[quote.client]) {
                        if (days >= 0 && days <= 30) {
                            groupByClient[quote.client]['0-30'] += conversion
                        } else if (days >= 31 && days <= 60) {
                            groupByClient[quote.client]['31-60'] += conversion
                        } else if (days >= 61 && days <= 90) {
                            groupByClient[quote.client]['61-90'] += conversion
                        } else if (days >= 91 && days <= 180) {
                            groupByClient[quote.client]['91-180'] += conversion
                        } else if (days >= 181 && days <= 360) {
                            groupByClient[quote.client]['181-360'] += conversion
                        } else if (days >= 361) {
                            groupByClient[quote.client]['>361'] += conversion
                        }
                    } else {
                        groupByClient[quote.client] = {
                            client: quote.client,
                            currency: 'USD',
                            '0-30': 0,
                            '31-60': 0,
                            '61-90': 0,
                            '91-180': 0,
                            '181-360': 0,
                            '>361': 0,
                        }
                        if (days >= 0 && days <= 30) {
                            groupByClient[quote.client]['0-30'] += conversion
                        } else if (days >= 31 && days <= 60) {
                            groupByClient[quote.client]['31-60'] += conversion
                        } else if (days >= 61 && days <= 90) {
                            groupByClient[quote.client]['61-90'] += conversion
                        } else if (days >= 91 && days <= 180) {
                            groupByClient[quote.client]['91-180'] += conversion
                        } else if (days >= 181 && days <= 360) {
                            groupByClient[quote.client]['181-360'] += conversion
                        } else if (days >= 361) {
                            groupByClient[quote.client]['>361'] += conversion
                        }
                    }
                }
            }
            return groupByClient
        },
        sortByDays() {
            this.showDays = !this.showDays
            this.localQuotes.sort((a, b) => {
                const aValue = a.days || null
                const bValue = b.days || null

                if (aValue === null) {
                    return bValue !== null ? 1 : 0
                }

                if (bValue === null) {
                    return aValue !== null ? -1 : 0
                }
                return bValue - aValue
            })
        },
        daysSinceLastMessage(item) {
            const selectedChat = this.chats.find(
                chat => chat.id == item.quoteId
            )
            if (selectedChat && selectedChat.lastMessage) {
                const lastMessageDate = moment.unix(
                    selectedChat.lastMessage.createdOn._seconds
                )
                const currentDate = moment.unix(Date.now() / 1000)
                return currentDate.diff(lastMessageDate, 'days') + ' days ago'
            } else if (selectedChat && !selectedChat.lastMessage) {
                return 'no messages'
            }
            return ''
        },
        getDays(item) {
            if (!item.sentDate && !item.lastUpdate) {
                item.days = 0
                return ''
            }
            const date = item.sentDate || item.lastUpdate
            const quoteDate = moment(
                moment.unix(date._seconds || date.seconds).format('YYYY-MM-DD')
            )
            const currentDate = moment(
                moment.unix(Date.now() / 1000).format('YYYY-MM-DD')
            )
            item.days = currentDate.diff(quoteDate, 'days')
            return currentDate.diff(quoteDate, 'days')
        },
        getDateFormat(date) {
            return date
                ? moment
                      .unix(date._seconds || date.seconds)
                      .format('YYYY-MM-DD')
                : ''
        },
        activateSort(param) {
            this.parameter = param
            if (this.parameter == 'days') {
                this.showDays = !this.showDays
            }
            this.show = !this.show
        },
        sortItems() {
            if (this.parameter != 'sentDate') {
                if (this.show || this.showDays) {
                    this.localQuotes.sort((a, b) => {
                        if (!a[this.parameter]) {
                            return b[this.parameter] ? b[this.parameter] : 0
                        }

                        if (!b[this.parameter]) {
                            return a[this.parameter] ? -a[this.parameter] : 0
                        }

                        return b[this.parameter] - a[this.parameter]
                    })
                } else {
                    this.localQuotes.sort((a, b) => {
                        if (!a[this.parameter]) {
                            return b[this.parameter] ? -b[this.parameter] : 0
                        }

                        if (!b[this.parameter]) {
                            return a[this.parameter] ? a[this.parameter] : 0
                        }

                        return a[this.parameter] - b[this.parameter]
                    })
                }
            } else {
                if (this.show) {
                    this.localQuotes.sort((a, b) => {
                        const aValue = a[this.parameter]
                            ? a[this.parameter]._seconds
                            : null
                        const bValue = b[this.parameter]
                            ? b[this.parameter]._seconds
                            : null

                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }

                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return bValue - aValue
                    })
                } else {
                    this.localQuotes.sort((a, b) => {
                        const aValue =
                            a[this.parameter] && a[this.parameter] != null
                                ? a[this.parameter]._seconds
                                : null
                        const bValue =
                            b[this.parameter] && b[this.parameter] != null
                                ? b[this.parameter]._seconds
                                : null

                        if (aValue === null) {
                            return bValue !== null ? 1 : 0
                        }

                        if (bValue === null) {
                            return aValue !== null ? -1 : 0
                        }
                        return aValue - bValue
                    })
                }
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight - 21
            this.quotesHeight =
                window.innerHeight - this.$vuetify.application.top - 70
        },
        filterNumber(item) {
            if (item.id) {
                return item.id.toString().includes(this.quoteNumber)
            } else {
                return false
            }
        },
        filterClient(item) {
            if (item.client) {
                return item.client
                    .toLowerCase()
                    .includes(this.quoteClient.toLowerCase())
            } else {
                return false
            }
        },
        filterProject(item) {
            return item.project
                .toLowerCase()
                .includes(this.quoteProject.toLowerCase())
        },
        filterCountry(item) {
            if (item.country) {
                return item.country
                    .toLowerCase()
                    .includes(this.quoteCountry.toLowerCase())
            } else {
                return false
            }
        },
        filterCurrency(item) {
            return item.currency
                .toString()
                .toLowerCase()
                .includes(this.quoteCurrency.toLowerCase())
        },
        filterPrice(item) {
            return item.price.toString().includes(this.quotePrice)
        },
        filterTrm(item) {
            return item.exchange.toString().includes(this.quoteTrm)
        },
        filterAccountManager(item) {
            return item.accountManager
                .toLowerCase()
                .includes(this.quoteAccountManager.toLowerCase())
        },
        filterCollaborators(item) {
            let res = false
            for (let i = 0; i < item.collaborators.length; i++) {
                res = item.collaborators[i]
                    .toLowerCase()
                    .includes(this.quoteCollaborator.toLowerCase())
                if (res) break
            }
            return res
        },
        filterTags(item) {
            let res = false
            for (let i = 0; i < item.tags.length; i++) {
                res = item.tags[i]
                    .toLowerCase()
                    .includes(this.quoteTag.toLowerCase())
                if (res) break
            }
            return res
        },
        filterProbability(item) {
            return (
                item.probability &&
                item.probability
                    .toLowerCase()
                    .includes(this.quoteProbability.toLowerCase())
            )
        },
        filterDays(item) {
            return `${item.days}` == `${this.days}`
        },
        filterSentDate(item) {
            const date = item.sentDate
                ? moment
                      .unix(item.sentDate._seconds || item.sentDate.seconds)
                      .format('YYYY-MM-DD')
                : ''
            return (
                date &&
                date.toLowerCase().includes(this.quoteSentDate.toLowerCase())
            )
        },
        filterExpectedDate(item) {
            return (
                item.expectedDate &&
                item.expectedDate
                    .toLowerCase()
                    .includes(this.quoteExpectedDate.toLowerCase())
            )
        },
        async openQuote(item) {
            try {
                this.heightPopUp = window.innerHeight - 62
                this.selectedQuote = await this.openQuoteFromTable(item.quoteId)
                this.openQuoteDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: item.currency,
                minimumFractionDigits: 0,
            }).format(item.price ? item.price.toFixed(0) : 0)
        },
    },
}
</script>

<style scoped>
.v-data-table {
    border: 1px solid #eeeeee;
}
.v-data-table >>> thead {
    background-color: #eeeeee;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
#virtual-scroll-table >>> tbody {
    font-weight: bold;
}
</style>
